import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import PageTextBlock from '../components/pageTextBlock';
import CenteredTextBlock from '../components/centeredTextBlock';
import { PreHeader, D2, D3, P1, P2, ButtonText } from '../theme/typography';
import Button from '../components/buttons';
import Spacer from '../components/spacer';
import BottomCut from '../components/bottomCut';
import AnimatedStripe from '../components/animatedStripe';
import FormInput2 from '../components/formInput2';

// Asset Imports
import Stripes05SVG from '../images/stripes/stripes05.inline.svg';
import Stripes03SVG from '../images/stripes/stripes03.inline.svg';
import ClockSVG from '../images/icons/clock.inline.svg';
import BriefcaseSVG from '../images/icons/briefcase.inline.svg';
import GlobeSVG from '../images/icons/globe.inline.svg';
import CreditCardSVG from '../images/icons/credit-card.inline.svg';
import TickBubbleSVG from '../images/icons/tickBubble.inline.svg';

// Styled Components
const OpenRolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 80px;
`;

const OpenRoleWrapper = styled.div`
  position: relative;
`;

const OpenRoleTile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border-radius: 8px;
  padding: 56px 32px;
  box-shadow: var(--box-shadow-coloured);
  z-index: 1;

  @media (min-width: 500px) {
    padding: 56px 56px;
  }

  @media (min-width: 800px) {
    padding: 56px 80px;
  }
`;

const OpenRoleStripe = styled(AnimatedStripe)`
  position: absolute;
  width: 275px;
  height: 200px;
  bottom: -50px;
  right: -100px;
  z-index: 0;
`;

const JobPreHeader = styled(PreHeader)`
  color: var(--blue2);
`;

const JobTitle = styled(D2)`
  color: var(--blue1);
`;

const JobDescription = styled(P1)`
  color: var(--blue1);
  white-space: pre-wrap;
`;

const PillContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
`;

const Pill = styled(ButtonText)`
  padding: 0 16px;
  display: flex;
  padding-top: 5px;
  padding-bottom: 3px;
  border-radius: 16.5px;
  white-space: nowrap;
  color: var(--blue1);
  background: var(--blue6);
  align-items: center;
`;

const PillIconStyles = css`
  height: 16px;
  width: 16px;
  stroke: var(--blue1);
  margin-top: -2px;
  margin-right: 8px;
`;

const ClockIcon = styled(ClockSVG)`
  ${PillIconStyles};
`;
const BriefcaseIcon = styled(BriefcaseSVG)`
  ${PillIconStyles};
`;
const GlobeIcon = styled(GlobeSVG)`
  ${PillIconStyles};
`;
const CreditCardIcon = styled(CreditCardSVG)`
  ${PillIconStyles};
`;

const SkillRequirementsTitle = styled(D3)`
  color: var(--blue1);
`;

const SkillsContainer = styled.div`
  --bulletGap: 16px;
  display: flex;
  flex-direction: column;
  gap: var(--bulletGap);

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const TicksColumnContainer = styled.div`
  flex-grow: 1;
  display: flex;
  gap: var(--bulletGap);
  flex-direction: column;
  @media (min-width: 800px) {
    max-width: 45%;
  }
`;

const TickContainer = styled.div`
  display: flex;
  padding-right: 8px;
  color: var(--blue1);
`;

const TickBubble = styled(TickBubbleSVG)`
  margin-right: 16px;
  min-width: 24px;
  > circle {
    fill: var(--tick-bubble-fill, var(--accent));
  }
`;

const ApplyButton = styled(Button)`
  margin-left: auto;
`;

const FormWrapper = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
  overflow: visible;
`;

const FormTile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 48px 32px 64px 32px;
  background: var(--blue1);
  border-radius: 8px;
  box-shadow: var(--box-shadow-xl);
`;

const FormTitle = styled(PreHeader)`
  margin: 0 auto 32px auto;
  color: var(--accent);
`;

const FormDualRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: 800px) {
    flex-direction: row;
  }
`;

const FormStripes01 = styled(AnimatedStripe)`
  position: absolute;
  width: 500px;
  height: 350px;
  top: -30px;
  left: -200px;
  z-index: -1;

  @media (min-width: 1024px) {
    width: 450px;
    height: 300px;
    top: -30px;
    left: -80px;
  }

  @media (min-width: 1300px) {
    left: -190px;
    top: -40px;
    width: 500px;
    height: 350px;
  }
`;

const FormStripes02 = styled(AnimatedStripe)`
  position: absolute;
  width: 275px;
  height: 200px;
  bottom: -8%;
  right: -10%;
  z-index: -1;
`;

const SendMessageButton = styled(Button)`
  width: 100%;

  &:disabled {
    background-color: var(--blue4);
    opacity: 0.6;
    cursor: unset;

    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, `C'mon your name is longer than that!`)
    .required(`Required`),
  lastName: Yup.string().required(`Required`),
  email: Yup.string().email(`Must be a valid email.`).required(`Required`),
  phone: Yup.string()
    .matches(
      /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/,
      `Must be a valid phone number.`,
    )
    .required(`Required`),
  portfolio: Yup.string().matches(
    /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm,
    `Must be a valid url`,
  ),
  message: Yup.string(),
});

const CareersPage = ({ data }) => {
  const OpenRoles = data.allSanityOpenRole.nodes;

  return (
    <>
      <SEO title='Careers' />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section
        light
        cuts='bottomCut'
        style={{ marginTop: 'var(--site-header-height)' }}
      >
        <Container>
          <CenteredHeaderBlock
            data={{
              preHeader: 'Careers',
              title: 'Join The Flock',
              body: `We’d love to hear from you, ${
                OpenRoles.length >= 1
                  ? `check out our open roles below or`
                  : `We don't have any open roles at the moment, but`
              } get in touch via the contact form to discuss joining the Rubberduck team!`,
            }}
          />
        </Container>
        <BottomCut dark={OpenRoles.length >= 1} />
      </Section>
      {OpenRoles.length >= 1 && (
        <Section dark cuts='topAndBottomCut' style={{ overflowX: 'clip' }}>
          <Container>
            <PageTextBlock
              data={{ preHeader: 'Open Roles', title: `We're Hiring` }}
            />
            <Spacer size={32} />
            <OpenRolesContainer>
              {OpenRoles.map((OpenRole, index) => (
                <OpenRoleWrapper key={index}>
                  <OpenRoleTile>
                    <JobPreHeader>Open Role</JobPreHeader>
                    <JobTitle>{OpenRole.jobTitle}</JobTitle>
                    <Spacer size={16} />
                    <PillContainer>
                      <Pill>
                        <ClockIcon />
                        {OpenRole.jobType}
                      </Pill>
                      {OpenRole.experienceRequired != null && (
                        <Pill>
                          <BriefcaseIcon />
                          {`${OpenRole.experienceRequired} years experience`}
                        </Pill>
                      )}
                      <Pill>
                        <GlobeIcon />
                        {
                          {
                            hybridRemote: <>Hybrid Remote</>,
                            inPerson: <>In Person</>,
                            remote: <>Remote</>,
                          }[OpenRole.location]
                        }
                      </Pill>
                      {OpenRole.driversLicence && (
                        <Pill>
                          <CreditCardIcon />
                          Full Drivers Licence
                        </Pill>
                      )}
                    </PillContainer>
                    <Spacer size={32} />

                    <JobDescription>{OpenRole.jobDescription}</JobDescription>
                    {OpenRole.includeSkillRequirements && (
                      <>
                        <Spacer size={32} />
                        <SkillRequirementsTitle>
                          Core Skill Requirements
                        </SkillRequirementsTitle>
                        <Spacer size={16} />
                        <SkillsContainer>
                          <TicksColumnContainer>
                            <TickContainer>
                              <TickBubble />
                              <P2>{OpenRole.skillRequirement1}</P2>
                            </TickContainer>
                            <TickContainer>
                              <TickBubble />
                              <P2>{OpenRole.skillRequirement2}</P2>
                            </TickContainer>
                            <TickContainer>
                              <TickBubble />
                              <P2>{OpenRole.skillRequirement3}</P2>
                            </TickContainer>
                          </TicksColumnContainer>
                          <TicksColumnContainer>
                            <TickContainer>
                              <TickBubble />
                              <P2>{OpenRole.skillRequirement4}</P2>
                            </TickContainer>
                            <TickContainer>
                              <TickBubble />
                              <P2>{OpenRole.skillRequirement5}</P2>
                            </TickContainer>
                            <TickContainer>
                              <TickBubble />
                              <P2>{OpenRole.skillRequirement6}</P2>
                            </TickContainer>
                          </TicksColumnContainer>
                        </SkillsContainer>
                      </>
                    )}
                    <Spacer size={48} />
                    <ApplyButton
                      data={{
                        label: `Apply Now`,
                        type: 'primary',
                        arrow: true,
                        path: '/contact',
                      }}
                      label={`Apply Now`}
                      type={'primary'}
                      arrow
                      path={OpenRole.slug.current}
                    />
                  </OpenRoleTile>
                  <OpenRoleStripe direction='left'>
                    <Stripes03SVG style={{ transform: `scaleX(-1)` }} />
                  </OpenRoleStripe>
                </OpenRoleWrapper>
              ))}
            </OpenRolesContainer>
          </Container>
          <BottomCut />
        </Section>
      )}
      <Section cuts='topandBottomCut' style={{ overflowX: 'clip' }}>
        <Container>
          <CenteredTextBlock
            data={{
              preHeader: 'Get in touch',
              title: `Keen to join the team?`,
              body: `We’re always on the lookout for enthusiastic ducks to join our flock. If you don’t see a suitable role open above, get in touch below!`,
            }}
          />
          <Spacer size={64} />
          <FormWrapper>
            <FormStripes01 direction='leftAndUp'>
              <Stripes05SVG />
            </FormStripes01>
            <FormTile>
              <FormTitle>Application Form</FormTitle>
              <Formik
                initialValues={{
                  'form-name': `Careers Form`,
                  'bot-field': ``,
                  firstName: ``,
                  lastName: ``,
                  email: ``,
                  phone: ``,
                  portfolio: ``,
                  message: ``,
                }}
                validationSchema={validationSchema}
              >
                {({
                  handleChange,
                  handleBlur,
                  values,
                  errors,
                  touched,
                  isValid,
                }) => (
                  <form
                    name='Careers Form'
                    method='POST'
                    data-netlify='true'
                    data-netlify-honeypot='bot-field'
                    action='/success/'
                  >
                    {/* The `form-name` hidden field is required to support form
                submissions in Netlify */}
                    <input
                      type='hidden'
                      name='form-name'
                      value='Careers Form'
                    />
                    <p hidden>
                      Don’t fill this out if you're a human:
                      <input name='bot-field' />
                    </p>
                    <FormDualRow>
                      <FormInput2
                        name='firstName'
                        label='First Name'
                        placeholder='John'
                        required
                        dark
                        value={values.firstName}
                        onChange={handleChange('firstName')}
                        onBlur={handleBlur('firstName')}
                        errorCase={!!errors.firstName && touched.firstName}
                        errorMsg={errors.firstName}
                      />
                      <FormInput2
                        name='lastName'
                        label='Last Name'
                        placeholder='Murphy'
                        required
                        dark
                        value={values.lastName}
                        onChange={handleChange('lastName')}
                        onBlur={handleBlur('lastName')}
                        errorCase={!!errors.lastName && touched.lastName}
                        errorMsg={errors.lastName}
                      />
                    </FormDualRow>
                    <Spacer size={24} />
                    <FormDualRow>
                      <FormInput2
                        name='email'
                        label='Email'
                        placeholder='johnmurphy@gmail.com'
                        required
                        dark
                        value={values.email}
                        onChange={handleChange('email')}
                        onBlur={handleBlur('email')}
                        errorCase={!!errors.email && touched.email}
                        errorMsg={errors.email}
                      />
                      <FormInput2
                        name='phone'
                        label='Phone'
                        placeholder='0123456789'
                        required
                        dark
                        value={values.phone}
                        onChange={handleChange('phone')}
                        onBlur={handleBlur('phone')}
                        errorCase={!!errors.phone && touched.phone}
                        errorMsg={errors.phone}
                      />
                    </FormDualRow>
                    <Spacer size={24} />
                    <FormInput2
                      name='portfolio'
                      label='Portfolio Link'
                      placeholder='www.google.com'
                      dark
                      value={values.portfolio}
                      onChange={handleChange('portfolio')}
                      onBlur={handleBlur('portfolio')}
                      errorCase={!!errors.portfolio && touched.portfolio}
                      errorMsg={errors.portfolio}
                    />
                    <Spacer size={24} />
                    <FormInput2
                      name='message'
                      label='Message'
                      dark
                      multiline
                      value={values.message}
                      onChange={handleChange('message')}
                      onBlur={handleBlur('message')}
                      errorCase={!!errors.message && touched.message}
                      errorMsg={errors.message}
                    />
                    <Spacer size={48} />
                    <SendMessageButton
                      type='submit'
                      disabled={!isValid}
                      label='Submit'
                      arrow
                    />
                  </form>
                )}
              </Formik>
            </FormTile>
            <FormStripes02 direction='left'>
              <Stripes03SVG style={{ transform: `scaleX(-1)` }} />
            </FormStripes02>
          </FormWrapper>
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

// GraphQL Query
export const careersPageQuery = graphql`
  {
    allSanityOpenRole {
      nodes {
        jobTitle
        slug {
          current
        }
        jobDescription
        jobType
        experienceRequired
        location
        driversLicence
        includeSkillRequirements
        skillRequirement1
        skillRequirement2
        skillRequirement3
        skillRequirement4
        skillRequirement5
        skillRequirement6
      }
    }
  }
`;

export default CareersPage;
